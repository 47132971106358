// Handle Overlays Opening

const OverlayTogglers = document.querySelectorAll('.toggle-overlay');
const OverlayCloser = document.querySelector('.overlay-closer');
const Overlays = document.querySelectorAll('.overlay');
const Main = document.querySelector('main');
const siteTitle = document.querySelector('.site-title');
const menuEl = document.querySelector('.menu');

OverlayTogglers.forEach((toggler) => {
    toggler.addEventListener("click", function (e) {
        e.preventDefault();
        const currentScroll = Main.scrollTop;
        OverlayTogglers.forEach((toggler) => {
            toggler.classList.remove('active');
        });

        toggler.classList.add('active');
        const target = document.querySelector(toggler.getAttribute('href'));
        showOverlay(target);
        console.log(currentScroll);
    });
});

function showOverlay(target) {

    Overlays.forEach((overlay) => {
        overlay.classList.remove('show');
    });
    target.classList.add('show');
    OverlayCloser.classList.add('overlay-shown');
    Main.style.display = "none";
    document.body.classList.add('overlay--opened');
}


// Handle Overlays Closing

const OverlayClosers = document.querySelectorAll('.overlay-closer');

OverlayCloser.addEventListener("click", function (e) {
    hideOverlays(e);
});

function hideOverlays(e) {
    document.body.classList.remove('overlay--opened');
    if (OverlayCloser.classList.contains('overlay-shown')) {
        Main.style.display = null;
        OverlayCloser.classList.remove('overlay-shown');

        OverlayTogglers.forEach((toggler) => {
            toggler.classList.remove('active');
        });
        Overlays.forEach((overlay) => {
            e.preventDefault();
            overlay.classList.remove('show');
        });
    }
}