const scrollContainer = document.querySelector('main');
const siteTitle = document.querySelector('.site-title');
const menuEl = document.querySelector('.menu');
const overlayCloser = document.querySelector('.overlay-closer');

scrollContainer.addEventListener("scroll", () => {
    if (!overlayCloser.classList.contains('overlay-shown')) {
        if (scrollContainer.scrollTop > 300) {
            menuEl.classList.add('show');
            siteTitle.classList.remove('show');
        }
    }

});