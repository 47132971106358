__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import { onLoading } from "./_modules/website";
import { loadPage } from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import "./_modules/menu";
import "./_modules/collapse";
import "./_modules/scroll";



document.addEventListener("DOMContentLoaded", () => {
  var sm = ($( window ).width() <= 768);
  var md = ($(window).width() > 768 && $(window).width() < 1024);
  var lg = ($( window ).width() > 1024);
  var mobilemenu;
  var ww = $( window ).width();

  var isMobile = {
    Android: function() {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
  };


  document.fonts.ready.then(function() {


    if(isMobile.any()) {
      $('body').addClass('touch');
    }




    resize_window();

    $(window).resize(function() {
      resize_window();
    });


    $('.footer-infos #contact-toggle').click(function (e){
      e.preventDefault();
      $('.contact-infos').css("display","flex");
    });

    $('#close-toggle').click(function (e){
      $('.contact-infos').css("display","none");
    });

    $( window ).on( "orientationchange", function( event ) {
      if ($(".list-wrap").hasClass("appear")) {
        $(".list-wrap").hide().removeClass("appear").show();
        show_left_img();
        $('#logo').fadeIn(450);
        $('.toggle-nav').removeClass('moveup');
      }
    });


    $('body').imagesLoaded( function() {

      $('.grid').packery({
        // options
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true,
        transitionDuration: "0.4s",

      });

      $('.grid-item').each(function () {
        var x = $(this).position();

        if(x.left == 0){
          $(this).addClass("left-column");
        }else{
          $(this).addClass("right-column");
        }
      });

      $('.grid').css("opacity","1");
    });

    $('.image-wrap').each(function (index) {

      var  n =  index+1;
      var container = ".image-wrap:nth-of-type("+n+")";

      $(container).imagesLoaded( function() {
        $(container).css("opacity","1");
      });
    });






    // PROJECT SCROLL IMAGES ON DESKTOP

    $(".project-images-wrap").mousewheel(function(event, delta) {

      lazyload();

      if(!isMobile.any()){

        this.scrollLeft -= (delta * event.deltaFactor);
        event.preventDefault();

        if((this.scrollLeft > 1) && ($('.project-text-wrap').hasClass("appear")) && mobilemenu == false){
          $('.project-text-wrap').removeClass("appear");
          $('.show-text').removeClass("open");
        }

        if((this.scrollLeft == 0 ) && ($('.project-text-wrap:not("appear")')) && mobilemenu == false){
          $('.project-text-wrap').addClass("appear");
          $('.show-text').addClass("open");
        }

      };
    });

    /*$(".project-images-wrap").on('touchmove', function() { //touchmove works for iOS, I don't know if Android supports it
        $(".project-images-wrap").trigger('mousewheel');
        console.log('touchmove');
    });*/

    // PROJECT SCROLL IMAGES ON MOBILE

    /*$('.project-images-wrap').swipe({


    swipe: function(event, direction, distance, duration, fingerCount, fingerData) {

        var actualscroll = $(this).scrollLeft();
        var scrolldistance = $(window).innerWidth();

        if (direction == 'left') $(this).animate({scrollLeft: actualscroll + scrolldistance }, 200,"swing");
        if (direction == 'right') $(this).animate({scrollLeft: actualscroll - scrolldistance }, 200,"swing");

        console.log(actualscroll);

    },
    allowPageScroll:"vertical"

    });*/








    // MENU HOVER DESKTOP

    $(".nav-link").not('.portfolio').hover(function(){

      if(mobilemenu == false){
        $(this).find('.list-wrap').addClass('appear');
        hide_left_img();
      }

    }, function(){

      if(mobilemenu == false) {

        $(this).find('.list-wrap').removeClass('appear');
        show_left_img();
      }
    });

    // MENU TOUCH DEVICES

    // Show list-wrap on click

    $(".nav-link .toggle-nav").click(function() {

      if(mobilemenu == true) {
        $('.list-wrap').removeClass('appear');
        $('.toggle-nav').removeClass("moveup");
        $(this).addClass('moveup');
        $('.quit').show();
        console.log($(this).parent().find('.list-wrap').addClass("appear"));
        hide_project_nav();
      }
    });

    // Hide the menu

    $(".quit").click(function(){
      if(mobilemenu == true) {
        $(".list-wrap").removeClass('appear');
        $('.toggle-nav').removeClass("moveup");
        $('.quit').fadeOut(200);
        show_project_nav();
      }
    });

    // Project touch move left

    // PROJECT TEXT INTERACTION

    // show text button

    $('.show-text').click(function () {
      $(this).toggleClass('open');
      $(".project-text-wrap").toggleClass("appear");
      $(".project-images-wrap").toggleClass("hide");
      if ($('.project-text-wrap').hasClass('appear')){
        $('.menu').hide();
      }else{
        $('.menu').show();
      }
    });

    // SHOW SUPINFOS DESKTOP

    $('.show-infos, .supinfos').hover(function () {
      if(mobilemenu == false){
        $(".supinfos").addClass("appear");
        $('.project-infos-wrap').css("z-index","99");
      }

    },function(){
      if(mobilemenu == false){
        $(".supinfos").removeClass("appear");
        $('.project-infos-wrap').css("z-index","0");
      }
    });


    // PROVISOIRE
    //
    // $('.show-infos').hover(function () {
    //     $('.nav-link:nth-of-type(2) a').addClass('remove');
    //     $('.nav-link:nth-of-type(3) a').addClass('remove');
    // },function (){
    //         $('.nav-link:nth-of-type(2) a').removeClass('remove');
    //         $('.nav-link:nth-of-type(3) a').removeClass('remove');
    // });
    //
    // $('.supinfos').hover(function () {
    //     $('.nav-link:nth-of-type(2) a').addClass('remove');
    //     $('.nav-link:nth-of-type(3) a').addClass('remove');
    // },function (){
    //     $('.nav-link:nth-of-type(2) a').removeClass('remove');
    //     $('.nav-link:nth-of-type(3) a').removeClass('remove');
    // });

    // SHOW SUPINFOS MOBILE

    $('.show-infos').click(function () {
      if(mobilemenu == true){
        $(".supinfos").addClass("appear");
        $('.project-text').addClass("hide");
        $(this).addClass('hide');
      }
    });

    $('.hide-infos').click(function () {
      if(mobilemenu == true){
        $(".supinfos").removeClass("appear");
        $('.project-text').removeClass("hide");
        $('.show-infos').removeClass('hide');
      }
    });
  });


  function lazyload(){
    // on récupère la dimension du scroll vertical de la fenêtre
    var winScrollTop = $('.project-images-wrap').scrollLeft();
    console.log(winScrollTop);

    // on récupère la hauteur de notre fenêtre
    var winHeight = $('.project-images-wrap').width() + $(window).width()/2;

    // position à gauche
    var posLeft = $('.project-images-wrap').offset().left;


    // on parcourt toutes les images ayant la classe "lazy"
    $('.image-wrap').each(function(index){

      // on récupère la position verticale de notre image dans la page
      var imgOTop = $(this).offset().left;
      var imgwidth =  $(this).innerWidth();

      console.log("seuil = "+(winHeight + winScrollTop));
      console.log("image width ="+imgwidth);
      console.log("hey = "+index+"  "+((imgOTop + imgwidth)));

      // on effectue les modification ssi elle est visible à l'écran

      if ((imgOTop - imgwidth) < winHeight){

        $(this)
            .addClass('lazy')
            .removeAttr('data-src');
      }
      /*if( imgOTop < (winHeight + winScrollTop) ) {

          // on change la source de notre image par celle présente dans l'attribut "data-src"
          // on supprime également la classe "lazy" ainsi que l'attribut "data-src" pour éviter de repasser dessus
          $(this)
              .attr( 'src', $(this).data('src') )
              .removeClass('lazy')
              .removeAttr('data-src');

      }*/
    });


  }



  function resize_window(){

    ww = $(window).width();

    if($(window).width() <= 768 || isMobile.any() ) {
      mobilemenu = true;
      $('.project-text-wrap').removeClass("appear");
      $('.show-text').removeClass('open');
      $('.project-images-wrap').removeClass("hide");
      $('.supinfos').removeClass("appear");
      $('.show-infos').removeClass("hide");
      $('.project-text').removeClass("hide");
      $('.menu').show();

    }else {
      mobilemenu = false;

      $('.project-text-wrap').show();
      $('.project-text-wrap').addClass("appear");
      $('.project-text').removeClass("hide");
      $('.show-text').addClass('open');
      $('.supinfos').removeClass("appear");

      if ($(".list-wrap").hasClass("appear")) {
        $(".list-wrap").hide().removeClass("appear").show();
        show_left_img();
        $('#logo').fadeIn(450);
        $('.toggle-nav').removeClass("moveup");
      }

      if($(".project-images-wrap").scrollLeft() !== 0){
        $('.project-text-wrap').removeClass("appear");
      }

    }
  }

  function hide_left_img(){

    $('.grid-item').each(function () {
      var x = $(this).position();

      if(x.left == 0){
        $(this).fadeOut(200);
      }
    });
    $('.blank').addClass("appear");
    $('.project-infos-wrap').fadeOut(200);
  }

  function show_left_img(){

    $('.grid-item').each(function () {
      var x = $(this).position();

      if(x.left == 0){
        $(this).fadeIn(200);
      }
    });
    $('.blank').removeClass("appear");
    $('.project-infos-wrap').fadeIn(200);

  }

  function toggle_project_text() {
    $('.project-text-wrap').toggleClass("appear");
  }

  function hide_project_nav(){
    $('.project-infos-wrap, .project-images-wrap .image-wrap').fadeOut(200);
  }

  function show_project_nav() {
    $('.project-infos-wrap, .project-images-wrap .image-wrap').fadeIn(200);
  }


});